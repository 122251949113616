<template>
  <div>
    <div class="navs">
        <a
            v-for="item of navList"
            :key="item.id"
            class="navs-router"
            @click="push(item.url)"
        >
          {{ item.title }}
        </a>
    </div>
    <router-view></router-view>  <!-- 动态内容根据子路由渲染 -->
  </div>
</template>
<script>


export default {
  data() {
    return {
      navList: [
        {
          id: "1",
          title: "PC游戏",
          url: "pcgame",
        },
        {
          id: "2",
          title: "PS游戏",
          url: "psgame",
        },
      ],
    };
  },
  methods: {
    push(val) {
      this.$router.push({
        name: val
      })
    }
  },
  mounted() {

  },
  components: {

    // Swiperlist,
  },
};
</script>

<style scoped>
.navs {
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  font-weight: bold;
}

.navs-router {
  font-size: 18px;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.navs-router:hover {
  transform: translateY(-5px);
  color: #6dbcff;
  cursor: pointer;
}
</style>
